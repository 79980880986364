import { AccountManager, type Account } from '@/entities/account';
import { $locale } from '@/features/application';
import type { SuccessMessage } from '@/features/bookingIframe';
import { BookingIframeManager } from '@/features/bookingIframe/model';
import { ConsentManager } from '@/features/consent/model';
import { onModalHidden, showModal, type ShowModalEventArgs } from '@/features/modal/model';

import { loadAppointments, updateApptDetailsById } from '@/features/myAppointments/model';
import { createEvent, createStore, restore, sample } from 'effector';
import { debug } from 'patronum';
import { $region } from '../../context';

import type { BookingFlowType } from '@/features/bookingIframe/api';
import type { StartAptBookingParams } from './contracts';

export const startAppointmentBooking = createEvent<StartAptBookingParams>();

export const $isInFlow = createStore<StartAptBookingParams | undefined>(undefined, { skipVoid: false });
export const $bookingDetails = restore(BookingIframeManager.flowSucceeded, null);

sample({
	clock: startAppointmentBooking,
	source: $isInFlow,
	filter: (src) => !src,
	fn: (_, clk) => clk,
	target: [$isInFlow, ConsentManager.requestConsentCheck.prepend((evt: StartAptBookingParams) => evt.partnerName)],
});

sample({
	clock: ConsentManager.consentCheckCompleted,
	source: [$isInFlow, $region, $locale] as const,
	filter: ([inFlow]) => !!inFlow, // inFlow.partnerId === consent.parentId
	fn: ([inFlow, province, locale], { partnerId }) => ({
		partnerId,
		specialtyId: inFlow!.specialtyId,
		serviceId: inFlow!.serviceId,
		locale,
		province,
	}),
	target: BookingIframeManager.createAppointment,
});

const isAvailableAndApptCreated = (
	source: readonly [Account | undefined, SuccessMessage | null, BookingFlowType | undefined]
): source is [Account, SuccessMessage | null, 'create'] => {
	return !!source[0] && source[2] === 'create';
};

sample({
	clock: BookingIframeManager.flowClosed,
	source: [AccountManager.$account, $bookingDetails, BookingIframeManager.$bookingFlowType] as const,
	filter: isAvailableAndApptCreated,
	fn: ([account, booking], _close): ShowModalEventArgs => {
		// parameters for the modal
		const identifier = 'booking-medme-success-modal';
		const templateVariables = {
			displayName: account.profile.preferredFirstName || account.profile.firstName || '',
			...booking,
		};

		return {
			identifier,
			templateProps: {},
			templateVariables,
		};
	},
	target: showModal,
});

sample({
	clock: onModalHidden, // When modal gets hidden
	source: $isInFlow, // We check if we are currently in the booking flow
	filter: (inFlow) => !!inFlow, // If so,
	fn: () => undefined, // reset $inFlow with an empty value
	target: $isInFlow,
});

// if user returns from iframe as reschedule or cancel appt then refresh appointments & details

sample({
	clock: BookingIframeManager.flowClosed,
	source: BookingIframeManager.$bookingFlowType,
	filter: (purpose) => ['reschedule', 'cancel'].includes(purpose),
	target: [loadAppointments, updateApptDetailsById],
});

$isInFlow.reset(BookingIframeManager.flowAbandoned, BookingIframeManager.flowClosed, BookingIframeManager.flowErrored);

debug({ trace: true }, { $isInFlow });
