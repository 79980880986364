import {
	EventName as EventNameEnum,
	getDataLayerName,
	initializeSnowplow,
	snowplow,
} from '@sdm/analytics-helper-library-pchweb';
import type { InitializationEnvironment } from '@sdm/analytics-helper-library-pchweb/src/types/index';
import { COMPONENT_CLEAR, EXCEPTION_CLEAR, HELIOS_REFRESH_LINKS_CLICK_TRACKING, LAYER_NAME } from './snowplowConstants';
import type { EventName, EventParamsMap } from './snowplowTypes';

const prodHostnames = ['pchealth.ca', 'drx-pch-prod.pchealth.ca', 'www.pchealth.ca'];

export function getSnowplowEnv(): InitializationEnvironment {
	const hostname = window.location.hostname;
	if (prodHostnames.includes(hostname)) {
		return 'prod';
	} else if (hostname.includes('uat')) {
		return 'qa';
	} else {
		return 'dev';
	}
}

export function addClearEventParams<T extends EventName>(event: T, params: EventParamsMap[T]) {
	const componentClearParams = {
		event: COMPONENT_CLEAR,
		component: null,
		form: null,
	};
	const exceptionClearParams = {
		event: EXCEPTION_CLEAR,
		exception: null,
	};
	const UiEngagementClearParams = {
		event: COMPONENT_CLEAR,
		component: null,
		custom_engagement: null,
	};

	switch (event) {
		case EventNameEnum.FormStart:
		case EventNameEnum.FormStep:
		case EventNameEnum.FormSubmit: {
			return [componentClearParams, params];
		}

		case EventNameEnum.Exception: {
			return [exceptionClearParams, params];
		}
		case EventNameEnum.UiEngagement: {
			return [UiEngagementClearParams, params];
		}
		default:
			return [params];
	}
}

export function updateAnalyticsLayer<T extends EventName>(
	event: T,
	params:
		| (EventParamsMap[T] | { event: string; component: null })[]
		| (EventParamsMap[T] | { event: string; exception: null })[]
) {
	const dataLayerName = getDataLayerName() ?? LAYER_NAME;
	const dataLayer = window[dataLayerName] as unknown[] | undefined;

	if (!dataLayer) {
		return console.error(`Window property '${dataLayerName}' is not defined or is not an array.`);
	}

	const isRecordWithEvent = (obj: unknown): obj is Record<string, unknown> =>
		!!obj && typeof obj === 'object' && 'event' in obj;

	const foundEvent = dataLayer?.find(
		(layer): layer is Record<string, unknown> => isRecordWithEvent(layer) && layer.event === event
	);

	if (foundEvent) {
		return console.error(`Window property '${event}' is already tracked in the analytics layer.`);
	}

	return params.forEach((param) => {
		dataLayer?.push({
			event,
			...param,
		});
	});
}

export function loadSnowplow() {
	const dataLayerName = getDataLayerName() ?? LAYER_NAME;
	return new Promise((resolve, reject) => {
		const dataLayer = window[dataLayerName];
		if (dataLayer) {
			resolve(dataLayer);
		} else {
			const env = getSnowplowEnv();
			const configOptions = {
				isVerbose: env !== 'prod',
				enableLinkClickAutoTracking: true,
			};
			initializeSnowplow(env, configOptions);
			if (window[dataLayerName]) {
				resolve(window[dataLayerName]);
			} else {
				reject(`Window property '${dataLayerName}' is not defined or is not an array.`);
			}
		}
	});
}

export const trackRender = (componentName: string) => {
	switch (componentName) {
		case HELIOS_REFRESH_LINKS_CLICK_TRACKING:
			snowplow?.refreshLinkClickTracking();
			break;
		default:
			return;
	}
};
