import { Box, Container, useTheme } from '@chakra-ui/react';

import type { FunctionComponent } from 'react';
import { Banner, BannerIconTextComponent, CardComponent, FAQComponent, GetTheApp } from '../component';

import { Notice } from '@/entities/notice';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import type { GetTheAppPageWithContent } from '../contentful';

export type GetTheAppTemplateProps = {
	content?: Extract<GetTheAppPageWithContent, { __typename?: 'GetTheAppTemplate' }>;
	loading?: boolean;
};

export const GetTheAppTemplate: FunctionComponent<GetTheAppTemplateProps> = ({ content, loading = false }) => {
	const theme = useTheme<Tokens>();
	if (content?.__typename !== 'GetTheAppTemplate') {
		return <Notice text="Invalid content returned from CMS" />;
	}

	const { heroBannerListCollection, featureCardsCollection, sectionBannerWithList, sectionBanner1, faq, getTheApp } =
		content;

	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				{heroBannerListCollection?.items[0] && (
					<Banner content={heroBannerListCollection?.items[0]} type="hero" loading={loading} />
				)}
				{featureCardsCollection?.items && featureCardsCollection?.items.length > 0 && (
					<CardComponent cards={featureCardsCollection?.items} />
				)}
				<BannerIconTextComponent banner={sectionBannerWithList} loading={loading} />
				<Banner content={sectionBanner1} type="feature" loading={loading} />
				<FAQComponent faq={faq} />
			</Container>
			<Box id={'gettheapp'}>
				<GetTheApp content={getTheApp} />
			</Box>
		</>
	);
};
