import { Alert, AlertDescription, AlertTitle, Container, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { type FunctionComponent, type PropsWithChildren } from 'react';

export type NoticeProps = {
	text: string;
	subtext?: string;
};
export const Notice: FunctionComponent<PropsWithChildren<NoticeProps>> = (props) => {
	const theme = useTheme<Tokens>();
	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0} data-testid="notice">
				<Alert>
					<AlertTitle>{props.text}</AlertTitle>
					{props.subtext && <AlertDescription>{props.subtext}</AlertDescription>}
					{props.children}
				</Alert>
			</Container>
		</>
	);
};
