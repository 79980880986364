import type { Locale } from '@/entities/routerBridge';
import type { BasicDropDownShape } from '../utilities/profile';

/**
 * Description:
 * Formats phone number
 * Max 10 chars
 * eg. xxx-xxx-xxxx
 */
export const phoneFormatAsString = (input: string) => {
	if (input.length === 10) {
		return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	}
	return input;
};

/**
 * Description:
 * Takes dash separated date as YYYY-MM-DD and returns slash separated
 * eg. DD / MM / YYYY
 */
export const dateFormatAsString = (input: string) => {
	if (!input) return '';
	const [year, month, day] = input.split('-');

	return `${day} / ${month} / ${year}`;
};

export const getAddressText = (street1: string, street2: string, city: string, province: string, postalCode: string) =>
	`${street1} ${street2} ${city} ${province} ${postalCode} CA`;

/**
 * Description:
 * This utility method was created to pass SonarQube test coverage
 */
export const getOptionalProp = (object: any, propName: string, fallback: any) => {
	if (propName in object) return object[propName];
	return fallback;
};

/**
 * Description:
 * Takes a comma delimited list, checks for 'Custom Option' and replaces it with a new value
 * eg. converts 'male, female, intersex, Custom Option', to 'male, female, intersex, Your Custom Option'
 */
export const replaceCustomOption = (options = '', oldCustomOption = '', newCustomOption = '') =>
	options.includes(oldCustomOption) ? options.replace(oldCustomOption, newCustomOption) : options;

/**
 * Description:
 * Formats value for Province dropdown
 */

export const findFullProvince = (provinceLabel: BasicDropDownShape, provinceValue: string) => {
	if (!provinceValue) return '';
	const provKey = provinceLabel.options.find((option: { key: string }) => option.key === provinceValue);
	return provKey ? provKey.value : '';
};

/**
 * Description
 * Produces Date object based on YYYY-MM-DD string
 */
export const parseDateString = (dateString: string): Date => {
	const yearMonthDate = dateString.split('-');
	return new Date(parseInt(yearMonthDate[0]), parseInt(yearMonthDate[1]) - 1, parseInt(yearMonthDate[2]));
};

/**
 * Customized month abbreviation formatter
 * @param date
 * @param locale
 * @returns
 */
const monthAbbreviationFormatter = (date: string, locale: Locale) => {
	let result = date;
	if (locale === 'en') {
		result = result.replace('Sep', 'Sept');
	}
	if (locale === 'fr') {
		['janv', 'févr', 'mars'].forEach((abbrev) => {
			if (result.includes(abbrev)) {
				result = result.replace(abbrev, abbrev.slice(0, 3));
			}
		});
	}
	return result.replace('.', '');
};

const timezoneLocaleMapper = {
	eastern: { en: 'ET', fr: 'HE' },
	pacific: { en: 'PT', fr: 'HP' },
	mountain: { en: 'MT', fr: 'HR' },
	central: { en: 'CT', fr: 'HC' },
	atlantic: { en: 'AT', fr: 'HA' },
};

/**
 * Format time for French: 1. replace ':' by 'h', 2. replace EN locale abbreviation by FR locale abbreviation
 * @param date
 * @param timeOptions
 * @example example return value: 13 h 55 HP
 */
const formatTimeForFrench = (date: Date, timeOptions: Intl.DateTimeFormatOptions) => {
	const enTimeString = date.toLocaleTimeString('en', timeOptions);
	let frTimeString = enTimeString.replace(':', ' h ');
	for (const [_, localeAbbreviation] of Object.entries(timezoneLocaleMapper)) {
		if (frTimeString.substring(frTimeString.lastIndexOf(' ') + 1) === localeAbbreviation.en) {
			frTimeString = frTimeString.replace(localeAbbreviation.en, localeAbbreviation.fr);
		}
	}
	return frTimeString;
};

export const formatDateToString = (date: Date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

/**
 * Description:
 * Format date for the booking management
 * @param date
 * @param locale
 * @example example return values:
 * 1. When there's year in input dateOptions:
 * en: { "formattedDate": "Wednesday, Oct 2, 2024", "formattedTime": "6:25 PM NT" }
 * fr: { "formattedDate": "mercredi 2 oct 2024", "formattedTime": "13 h 55 HP" }
 * 2. when there's no year in input dateOptions:
 * en: { "formattedDate": "Wednesday, Oct 2", "formattedTime": "6:25 PM NT" }
 * fr: { "formattedDate": "mercredi 2 oct", "formattedTime": "13 h 55 HP" }
 */
const formatDateForBookingManagement = (
	date: Date | undefined,
	locale: Locale,
	dateOptions: Intl.DateTimeFormatOptions
) => {
	if (!date) return { formattedDate: '', formattedTime: '' };
	const isFrench = locale === 'fr';
	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: !isFrench,
		timeZoneName: 'shortGeneric',
	};
	const localeDateString = date.toLocaleDateString(locale, dateOptions);
	const formattedTimeFr = formatTimeForFrench(date, timeOptions);
	let formattedTime = isFrench ? formattedTimeFr : date.toLocaleTimeString(locale, timeOptions);
	// Special case for Newfoundland timezone
	formattedTime = formattedTime.replace('St Johns Time', locale === 'en' ? 'NT' : 'HT');
	return { formattedDate: monthAbbreviationFormatter(localeDateString, locale), formattedTime: formattedTime };
};

/**
 * Description:
 * Format date for the appointments page
 * @param date
 * @param locale
 * @example example return value:
 * en: { "formattedDate": "Wednesday, Oct 2", "formattedTime": "6:25 PM NT" }
 * fr: { "formattedDate": "mercredi 2 oct", "formattedTime": "13 h 55 HP" }
 */
export const formatDateForAppointments = (date: Date | undefined, locale: Locale) => {
	return formatDateForBookingManagement(date, locale, {
		weekday: 'long',
		month: 'short',
		day: 'numeric',
	});
};

/**
 * Description:
 * Format date for the appointment detail page
 * @param date
 * @param locale
 * @example example return value:
 * en: { "formattedDate": "Wednesday, Oct 2, 2024", "formattedTime": "6:25 PM NT" }
 * fr: { "formattedDate": "mercredi 2 oct 2024", "formattedTime": "13 h 55 HP" }
 */
export const formatDateForAppointmentDetail = (date: Date, locale: Locale) => {
	return formatDateForBookingManagement(date, locale, {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
};

/**
 * Description:
 * Formats number for en / fr in Canada
 * EN: 1,000
 * FR: 1 000
 */

export const formatNumberEnFr = (value = 0, locale = 'en') => {
	return Intl.NumberFormat(locale).format(value);
};

/**
 * Description:
 * Formats currency for en / fr in Canada
 * EN: $1,000
 * FR: 1 000 $
 * Accepts number of decimals: maximumFractionDigits
 * Accepts convertDollarToCents which will divide dollar by 100
 */

export const formatCurrencyEnFr = (
	value = 0,
	locale = 'en',
	maximumFractionDigits = 0,
	convertDollarToCents = true
) => {
	return Intl.NumberFormat(`${locale}-CA`, {
		style: 'currency',
		currency: 'CAD',
		maximumFractionDigits,
	}).format(convertDollarToCents ? value / 100 : value);
};

// SSR
export const isFrench = (locale: string) => locale === 'fr';

/**
 * Description:
 * Handy function for handling when a querystring returns it could be a string, an array of strings or undefined
 * Will return result based on the type of the querystring
 */

export const getQueryString = (querystring: string | string[] | undefined) => {
	return Array.isArray(querystring) ? querystring[0] : querystring || '';
};

export const longDateFormat = (dateString: Date, locale: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	};

	return dateString.toLocaleDateString(`${locale}-CA`, options);
};

export const shortDateFormat = (dateString: Date, localeCode: string) => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	};

	return dateString.toLocaleDateString(`${localeCode}`, options);
};

export const utcConvertedDateTime = (dateString: string): Date => {
	const formattedDate = dateString.includes('T') ? dateString : dateString.replace(' ', 'T');
	return new Date(`${formattedDate}Z`);
};

export const longFormattedDate = (dateString: string, locale: string): string => {
	return longDateFormat(utcConvertedDateTime(dateString), locale);
};

export const shortFormattedDate = (dateString: string, locale: string) => {
	return shortDateFormat(utcConvertedDateTime(dateString), locale);
};

export function isDateToday(input: Date | string) {
	return new Date(input).setHours(0, 0, 0, 0) == new Date(Date.now()).setHours(0, 0, 0, 0);
}

export const formatPhone = (phone: string) => {
	if (phone?.length >= 6) {
		return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}`;
	}
	return '';
};

export const formatEmail = (email: string) => {
	if (!email?.includes('@')) return '';
	const emailParts = email.split('@');
	const firstPart = emailParts[0];
	const secondPart = emailParts[1];
	return `${firstPart.at(0)}${'*'.repeat(firstPart.length - 2)}${firstPart.at(-1)}@${secondPart}`;
};
