import { LocationSelector } from '@ldfeplatform/drx-component-library.ui.atoms.location-selector';
import { Footer } from '@ldfeplatform/drx-component-library.ui.molecules.footer';
import { Masthead, type MastheadProps } from '@ldfeplatform/drx-component-library.ui.organisms.masthead';

import { AccountManager } from '@/entities/account';
import type { Locale } from '@/entities/routerBridge';
import { $session } from '@/features/application';
import type { SessionStatus } from '@/shared/session';
import { useLanguage } from '@/shared/useLanguage';
import { Box } from '@chakra-ui/react';
import { SimpleMasthead, type SimpleMastheadProps } from '@ldfeplatform/drx-component-library.ui.atoms.simple-masthead';
import {
	ActionDropdown,
	type ActionDropdownPropsItemsProps,
} from '@ldfeplatform/drx-component-library.ui.molecules.action-dropdown';
import type { FooterLinkProps } from '@ldfeplatform/drx-component-library.ui.molecules.footer';
import { useUnit } from 'effector-react';
import { useRouter } from 'next/router';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { MdAccountCircle, MdOutlineAccountCircle } from 'react-icons/md';
import type { MastheadVariant } from 'types';
import { CTACollectionComponent, PageLoader, SEO } from '../component';
import { Disclaimer } from '../component/Disclaimer';
import type { BasicPageProps, Provinces, StaticProps } from '../contentful/ssr';
import { useGeolocationContext } from '../context/GeolocationContext';
import { updateGTMDataLayer, usePageAnalytics } from '../hooks';
import { useConsent } from '../hooks/useConsent';
import { PCH } from '../services/bff/heliosUtils';
import { toggleOneTrust } from '../utilities/onetrust';
import { normalizeRoute } from '../utilities/routing';

export type PageProps = PropsWithChildren<Omit<StaticProps, keyof BasicPageProps> & { loading?: boolean }>;

export const updateFooterWithOneTrust = (footerLinks: FooterLinkProps[], isLocationQC: boolean): FooterLinkProps[] => {
	if (!isLocationQC) {
		return footerLinks?.filter((item) => item?.link !== '#cookiesettings');
	}
	return footerLinks?.map((item) => ({
		label: item?.label,
		link: item?.link,
		onClick: item?.link === '#cookiesettings' ? toggleOneTrust : item?.onClick,
	}));
};

export const Page: FunctionComponent<PageProps> = ({
	seo,
	masthead,
	footer,
	children,
	provinces,
	disclaimer,
	brandVariant,
	analytics,
	mastheadAction,
	mastheadVariant = 'full',
	loading = false,
}) => {
	const router = useRouter();

	const { locale } = useLanguage();
	usePageAnalytics({ ...analytics, locale });

	const session = useUnit($session);

	const { isLocationQC } = useConsent();

	const modifiedFooter = {
		...footer,
		languageToggleLink: footer.languageToggleLink
			? `/${footer.languageToggleLink}/${router.asPath}`.replace(/\/+/g, '/')
			: `/${router.asPath}`.replace(/\/+/g, '/'),
		footerLinks: updateFooterWithOneTrust(footer?.footerLinks, isLocationQC),
	};

	const position = normalizeRoute(router?.query?.slug) === 'downloadApp' ? 'left' : 'center';
	const isPCHBrand = brandVariant === PCH;

	return (
		<>
			<SEO settings={seo} />
			{isPCHBrand && (
				<RenderMasthead
					locale={locale}
					masthead={masthead}
					mastheadAction={mastheadAction}
					mastheadVariant={mastheadVariant ?? 'full'}
					position={position}
					provinces={provinces}
					status={session.status}
				/>
			)}

			<PageLoader loading={loading}>
				{children}
				<Disclaimer disclaimer={disclaimer} />
			</PageLoader>
			{isPCHBrand && <Footer {...modifiedFooter}></Footer>}
		</>
	);
};

type MastheadPosition = 'left' | 'center';

type RenderMastheadProps = {
	locale: Locale;
	masthead: MastheadProps;
	mastheadAction: StaticProps['mastheadAction'];
	mastheadVariant: MastheadVariant;
	position: MastheadPosition;
	provinces: Provinces;
	status: SessionStatus;
};

function RenderMasthead({
	locale,
	masthead,
	mastheadAction,
	mastheadVariant,
	position,
	provinces,
	status,
}: RenderMastheadProps) {
	const { geoLocation, setGeolocation } = useGeolocationContext();
	const { $account: account } = useUnit(AccountManager);

	const [, region] = geoLocation.split('-');

	const simpleMastheadProps: SimpleMastheadProps = {
		icon: {
			__typename: 'CallToAction',
			label: '',
			variant: 'primary',
			image: {
				asset: {
					url: masthead?.mastheadHeader.icon?.image?.asset?.url || '',
				},
				altText: masthead?.mastheadHeader?.icon?.image?.altText || '',
			},
			linkUrl: '/',
			target: '_self',
			dataAnalytics: '',
		},
		position,
	};

	const mastheadWithAuth = {
		...masthead,
		isAuthenticated: status === 'authenticated',
	};
	const profileName = `${account?.profile?.firstName || ''} ${account?.profile?.lastName || ''}`;

	const accountNav = masthead?.accountNavItem || [];
	const { name, actionDropdownItemChildren } = accountNav?.[0] || [];

	const accountNavData = {
		actionText: name || '',
		actionIcon: MdOutlineAccountCircle,
		actionIconOnEvent: MdAccountCircle,
		profileName,
		actionDropdownList:
			actionDropdownItemChildren?.map((listItem) => {
				return {
					name: listItem.label,
					url: listItem.link,
					actionItemIcon: listItem.icon,
				};
			}) || [],
	};

	const actionNavItem: ActionDropdownPropsItemsProps[] | undefined =
		status === 'authenticated' ? accountNavData.actionDropdownList : undefined;

	const headerChildren =
		status === 'authenticated' ? (
			<ActionDropdown {...accountNavData} />
		) : (
			<CTACollectionComponent items={mastheadAction?.ctas} />
		);
	if (mastheadVariant === 'simple') {
		return (
			<Box paddingY={{ base: 6, md: '1.625rem' }} paddingX={{ base: 6, md: 12 }}>
				<SimpleMasthead {...simpleMastheadProps} />
			</Box>
		);
	} else {
		return (
			<Masthead {...mastheadWithAuth} accountNavItem={actionNavItem} headerChildren={headerChildren}>
				<Box zIndex={1}>
					<LocationSelector
						dropDownMenuText={provinces.dropdownText}
						defaultLocation={region || provinces.defaultLocation}
						onLocationChange={(e) => {
							updateGTMDataLayer({
								provinceSelection: e,
								locale,
							});
							setGeolocation(e);
						}}
						provinces={provinces.province}
					></LocationSelector>
				</Box>
			</Masthead>
		);
	}
}
