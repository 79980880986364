import { type AuthUrlResponseV2 } from '../../services/midtier/booking-management/__generated';
import type { CreateAppointmentRequest } from '../../types/booking';

type BookingAppointmentBaseProps = {
	partnerId: string;
	locale: string;
	province: string;
	utm?: {
		campaign: string;
		medium: string;
		source: string;
	};
};

export type ModifyAppointmentPurpose = 'cancel' | 'reschedule';
export type CreatePurpose = 'create';
export type BookingFlowType = CreatePurpose | ModifyAppointmentPurpose;

export type ModifyAppointmentFxInput = BookingAppointmentBaseProps & {
	resourceId: string;
	purpose: ModifyAppointmentPurpose;
};

export type CreateAppointmentFxInput = BookingAppointmentBaseProps & {
	specialtyId?: string;
	serviceId?: string;
};

const createOrModifyBooking = async (
	params: CreateAppointmentFxInput | ModifyAppointmentFxInput,
	apiPath: 'createAppointment' | 'rescheduleAppointment' | 'cancelAppointment'
) => {
	const payload: CreateAppointmentRequest | ModifyAppointmentFxInput = params;
	const response = await fetch(`/api/booking/${apiPath}/`, {
		method: 'post',
		body: JSON.stringify(payload),
		headers: { 'Content-Type': 'application/json' },
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}

	const data: AuthUrlResponseV2 = await response.json();
	const url = new URL(data.url);
	url.searchParams.append('webview', 'true');
	if (params.utm) {
		url.searchParams.append('utm_campaign', params.utm.campaign);
		url.searchParams.append('utm_medium', params.utm.medium);
		url.searchParams.append('utm_source', params.utm.source);
	}
	return url;
};

export const createAppointment = (params: CreateAppointmentFxInput) =>
	createOrModifyBooking(params, 'createAppointment');

export const rescheduleAppointment = (params: ModifyAppointmentFxInput) =>
	createOrModifyBooking(params, 'rescheduleAppointment');

export const cancelAppointment = (params: ModifyAppointmentFxInput) =>
	createOrModifyBooking(params, 'cancelAppointment');
