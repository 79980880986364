import type { SimpleMastheadProps } from '@ldfeplatform/drx-component-library.ui.atoms.simple-masthead';

export const NUMBERSTEPS = 3;

export const simpleMastheadProps: SimpleMastheadProps = {
	icon: {
		__typename: 'CallToAction',
		label: '',
		variant: 'primary',
		image: {
			asset: {
				url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/digital-pharmacy/mock-data/pch_logo.png',
			},
			altText: 'PC Health logo',
		},
		linkUrl: '/',
		target: '_self',
		dataAnalytics: '',
	},
};

export const simpleMastheadPropsFR: SimpleMastheadProps = {
	icon: {
		__typename: 'CallToAction',
		label: '',
		variant: 'primary',
		image: {
			asset: {
				url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/grocery/2023/wk32/pch-homepage-rollout/masthead-logo/PCH_logo_FR.png',
			},
			altText: 'Logo de PC Santé',
		},
		linkUrl: '/',
		target: '_self',
		dataAnalytics: '',
	},
};
