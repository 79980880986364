import { pchTheme, sdmTheme } from '@ldfeplatform/drx-component-library.themes.theme';
import { type NextRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { dmrPchSlug, dmrSdmSlug } from '../contentful';

export const replaceStringTemplates = (stringTemplate: string | null, dynamicData: Record<string, any>) => {
	if (!stringTemplate) return '';
	if (stringTemplate && /#{(\w+)}/g.test(stringTemplate)) {
		return stringTemplate.replace(/#{(\w+)}/g, (_: string, name) => dynamicData?.[name] || '');
	}
	return stringTemplate;
};

export const getBannerTheme = (banner: string | undefined, router: NextRouter) => {
	let theme = banner === 'SDM' ? sdmTheme : pchTheme;
	if (router.query?.banner) {
		theme = router.query?.banner && router.query.banner.toString().toUpperCase() === 'SDM' ? sdmTheme : pchTheme;
	}
	return theme;
};

export const extractUtmParameters = (query: ParsedUrlQuery) => {
	const utmParams = {
		utm_campaign: query.utm_campaign?.toString(),
		utm_medium: query.utm_medium?.toString(),
		utm_source: query.utm_source?.toString(),
		utm_content: query.utm_content?.toString(),
		utm_term: query.utm_term?.toString(),
		utm_province: query.utm_province?.toString(),
		banner: query.banner?.toString(),
	};

	// Filter out nullish values (null & undefined)
	const filteredParams = Object.entries(utmParams)
		.filter(([, value]) => value !== null && value !== undefined)
		.reduce(
			(obj, [key, value]) => {
				if (value !== null && value !== undefined) {
					obj[key] = value;
				}
				return obj;
			},
			{} as Record<string, string>
		);

	// Add timestamp (if there are any non-null properties)
	if (Object.values(filteredParams).some((value) => value !== null && value !== undefined)) {
		filteredParams.timestamp = new Date().toISOString();
	}

	return filteredParams;
};

export const getUtmSourceBySlug = (slug: string | string[] | undefined) => {
	switch (slug) {
		case dmrPchSlug:
			return 'lcl';
		case dmrSdmSlug:
			return 'sdm';
		default:
			return '';
	}
};
