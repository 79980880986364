import { Box, Container, Text, useTheme } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import type { FunctionComponent } from 'react';

export type DisclaimerProps = {
	disclaimer?: Document | null;
};

export const Disclaimer: FunctionComponent<DisclaimerProps> = ({ disclaimer }) => {
	const theme = useTheme<Tokens>();
	if (!disclaimer) {
		return <></>;
	}
	return (
		<Box bg={theme.colors.greyLight}>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				<Text as="div" textAlign={'left'} px={{ base: 8, lg: 9, xl: 12 }} pt={9}>
					{documentToReactComponents(disclaimer)}
				</Text>
			</Container>
		</Box>
	);
};
