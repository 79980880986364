import { Environment } from '@/shared/config';
import { useEnvironment } from '@/shared/environment';
import { useLanguage } from '@/shared/useLanguage';
import Head from 'next/head';
import { type FunctionComponent, type ReactNode } from 'react';
import type { Maybe, SeoSettingsFieldsFragment } from '../../contentful';
import { defaultSeoValues } from './default-seo-values';
import type { LinkTag, OpenGraph, Robots, SeoSettings, Twitter } from './types';

export const renderOpenGraph = ({ images, url, type }: OpenGraph, canonicalUrl: string) => {
	const assets: ReactNode[] = [];
	if (images) {
		const { asset, altText } = images;
		if (asset.url && asset.mimeType && altText) {
			assets.push(
				<meta property="og:image" content={asset.url} />,
				<meta key="og:type" property="og:type" content={asset.mimeType} />,
				<meta property="og:image:alt" content={altText} />
			);
		}
	}

	return (
		<>
			{(url || canonicalUrl) && <meta property="og:url" content={url ?? canonicalUrl} />}
			{type && <meta key="og:type" property="og:type" content={type.toLowerCase()} />}
			{assets}
		</>
	);
};

export const renderTwitterTag = ({ cardType, site }: Twitter) => {
	return (
		<>
			{cardType && <meta name="twitter:card" content={cardType} />}
			{site && <meta name="twitter:site" content={site} />}
		</>
	);
};

export const renderAdditionalLinks = ({ links }: { links?: readonly LinkTag[] }) => {
	if (!links) {
		return <></>;
	}
	return links.map((link) => {
		const { crossOrigin: tagCrossOrigin, ...rest } = link;
		const crossOrigin =
			tagCrossOrigin === 'anonymous' || tagCrossOrigin === 'use-credentials' || tagCrossOrigin === ''
				? tagCrossOrigin
				: undefined;
		return <link key={`link${rest.keyOverride ?? rest.href}${rest.rel}`} {...rest} crossOrigin={crossOrigin} />;
	});
};

export const renderRobots = ({
	robots,
	DeploymentEnvironment = Environment.PRODUCTION,
}: {
	robots: Robots;
	DeploymentEnvironment?: Environment;
}) => {
	const isProdEnvironment = DeploymentEnvironment === Environment.PRODUCTION;
	const index = robots.metaRobotsIndex && isProdEnvironment ? 'index' : 'noindex';
	const follow = robots.crawlerFollow ? 'follow' : 'nofollow';

	return <meta name="robots" content={[index, follow].join(',')} />;
};

export type SEOProps = {
	settings?: Maybe<SeoSettingsFieldsFragment>;
};

export const SEO: FunctionComponent<SEOProps> = ({ settings }) => {
	const { locale } = useLanguage();
	const { DeploymentEnvironment } = useEnvironment();
	const data = settings ? deriveSeoPropsFromData(settings) : defaultSeoValues[locale];
	const { title, description, canonicalUrl, robots, openGraph, twitter, additionalMetaTags, additionalLinkTags } = data;
	return (
		<>
			<Head>
				{title && <title>{title}</title>}
				{description && <meta name="description" content={description} />}
				{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
				{renderRobots({ robots, DeploymentEnvironment })}
				{(openGraph?.title || title) && <meta property="og:title" content={openGraph?.title ?? title} />}
				{(openGraph?.description || description) && (
					<meta property="og:description" content={openGraph?.description ?? description} />
				)}
				{openGraph && renderOpenGraph(openGraph, canonicalUrl)}
				{twitter && renderTwitterTag(twitter)}
				{additionalMetaTags?.map((tag) => <meta key={`meta:${tag.name ?? tag.property ?? tag.httpEquiv}`} {...tag} />)}
				{renderAdditionalLinks({ links: additionalLinkTags })}
			</Head>
		</>
	);
};

export function valueOrDefault<T>(value: T | undefined, defaultValue: NonNullable<T>) {
	return value ?? defaultValue;
}

export function deriveSeoPropsFromData(tags?: Maybe<SeoSettingsFieldsFragment>): SeoSettings {
	return {
		title: valueOrDefault(tags?.title, ''),
		description: valueOrDefault(tags?.description, ''),
		canonicalUrl: valueOrDefault(tags?.canonicalUrl, ''),
		robots: {
			metaRobotsIndex: valueOrDefault(tags?.metaRobotsIndex, false),
			crawlerFollow: valueOrDefault(tags?.crawlerFollow, false),
		},
		additionalMetaTags: valueOrDefault(tags?.additionalMetaTags, []),
		additionalLinkTags: valueOrDefault(tags?.additionalLinkTags, []),
		openGraph: {
			title: valueOrDefault(tags?.openGraphTwitterTitle, ''),
			description: valueOrDefault(tags?.openGraphTwitterDescription, ''),
			type: valueOrDefault(tags?.openGraphType, ''),
			url: valueOrDefault(tags?.openGraphTwitterUrl, ''),
			images: {
				altText: valueOrDefault(tags?.openGraphTwitterImageReference?.altText, ''),
				asset: {
					url: valueOrDefault(tags?.openGraphTwitterImageReference?.asset?.url, null),
					mimeType: valueOrDefault(tags?.openGraphTwitterImageReference?.asset?.mimeType, null),
				},
			},
		},
		twitter: {
			cardType: valueOrDefault(tags?.twitterCard, ''),
			site: valueOrDefault(tags?.twitterSite, ''),
		},
	};
}
