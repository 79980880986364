import {
	Box,
	type BoxProps,
	type CreateToastFnReturn,
	type ToastId,
	useToast as useChakraToast,
	type UseToastOptions,
} from '@chakra-ui/react';
import { Toast as CustomToast } from '@ldfeplatform/drx-component-library.ui.molecules.toast';
import { useRef } from 'react';

export const useToast = () => {
	const toast = useChakraToast();
	const toastIdRef = useRef<ToastId>(0);
	const toastIdsRef = useRef<ToastId[]>([]);

	const closeOldest = (toast: CreateToastFnReturn, activeToasts: ToastId[]): ToastId[] => {
		toast.close(activeToasts[0]);
		return toastIdsRef.current.slice(1);
	};

	const createToastOptions = (
		isSuccess: boolean,
		message: string,
		description?: string,
		duration: number | null = 9000,
		styles?: BoxProps
	): UseToastOptions => ({
		duration: duration,
		render: () => (
			<Box {...styles}>
				<CustomToast
					status={isSuccess ? 'success' : 'error'}
					title={message}
					description={description}
					onToastClose={() => {
						if (toastIdRef.current) {
							toast.close(toastIdRef.current);
						}
					}}
				/>
			</Box>
		),
	});

	const displayToast = (
		isSuccess: boolean,
		message: string,
		description = '',
		duration: number | null = 9000,
		styles?: BoxProps
	) => {
		if (toastIdsRef.current.length === 2) {
			toastIdsRef.current = closeOldest(toast, toastIdsRef.current);
		}
		const toastId = toast(createToastOptions(isSuccess, message, description, duration, styles));

		toastIdRef.current = toastId;
		toastIdsRef.current.push(toastId);
	};

	const hideAllToast = () => {
		if (toast) {
			toast.closeAll();
		}
	};

	return { displayToast, hideAllToast };
};
