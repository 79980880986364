import { FallbackRegion, RegionCookie, SduiBffApiKey } from '@/shared/config';
import type { GetServerSidePropsContext } from 'next';

// BFF banner constants
export const PHX = 'pharmaprix';
export const SDM = 'shoppersdrugmart';
export const PCH = 'pchealth';

export type BrandVariant = typeof PCH | typeof SDM | typeof PHX;

export const getContentfulPreviewHeaders = (query: { preview?: 'true'; dateTime?: string }): Record<string, string> => {
	const headers: Record<string, string> = {
		'x-preview': query.preview || 'false',
		'x-preview-datetime': query.dateTime ?? new Date().toISOString(),
	};

	return headers;
};

export const getRequestOptions = (
	locale: string,
	context: GetServerSidePropsContext,
	banner: BrandVariant,
	urlPath: string,
	accessToken: string
) => {
	const geoLocation = context?.req?.cookies?.[RegionCookie] || FallbackRegion;
	const [, province] = geoLocation.split('-');
	const contentful = getContentfulPreviewHeaders(context.query);
	const headers: HeadersInit = {
		'accept-language': locale,
		'content-type': 'application/json',
		'x-preview': contentful['x-preview'],
		'x-preview-datetime': contentful['x-preview-datetime'],
		'x-application-type': 'Web',
		'x-apikey': SduiBffApiKey,
		Authorization: accessToken,
	};

	const effectiveBanner = banner ?? PCH;

	const bodyRaw: BodyInit = JSON.stringify({
		banner: effectiveBanner,
		province: province,
		urlPath: '/' + urlPath, //to meet the format required in sdm bff
	});

	const requestOptions: RequestInit = {
		method: 'POST',
		headers: headers,
		body: bodyRaw,
	};

	return requestOptions;
};
