type ExtensionValueType = 'valueString' | 'valueDecimal' | 'valueCoding';
export type ExtensionPair = { key: string; type: ExtensionValueType };

export const MTFHIRDataMappingContract: Record<string, ExtensionPair> = {
	healthcareServiceName: { key: 'healthecareservice-name', type: 'valueString' },
	pharmacyName: { key: 'pharmacy-name', type: 'valueString' },
	pharmacyAddress: { key: 'pharmacy-address', type: 'valueString' },
	pharmacyCity: { key: 'pharmacy-city', type: 'valueString' },
	pharmacyProvince: { key: 'pharmacy-province', type: 'valueString' },
	pharmacyPostalCode: { key: 'pharmacy-postal-code', type: 'valueString' },
	pharmacyLongitude: { key: 'pharmacy-longitude', type: 'valueDecimal' },
	pharmacyLatitude: { key: 'pharmacy-latitude', type: 'valueDecimal' },
	pharmacyPhone: { key: 'pharmacy-phone', type: 'valueString' },
	subtype: { key: 'subtype', type: 'valueString' },
	appointmentMethod: { key: 'appointment-method', type: 'valueCoding' },
	specialtyId: { key: 'specialty-id', type: 'valueString' },
	specialtyName: { key: 'specialty-name', type: 'valueString' },
	patientFirstName: { key: 'patient-first-name', type: 'valueString' },
	patientLastName: { key: 'patient-last-name', type: 'valueString' },
};
