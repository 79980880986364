import { Notice } from '@/entities/notice';
import { getSDUINavCardProps } from '@/features/customActions';
import { Box, Container, Flex, Grid, GridItem, Spacer, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { BreadcrumbNav } from '@ldfeplatform/drx-component-library.ui.atoms.breadcrumb';
import type { BreadCrumbItem } from '@ldfeplatform/drx-component-library.ui.atoms.breadcrumb/dist/breadcrumb';
import { RichTextRenderer } from '@ldfeplatform/drx-component-library.ui.atoms.rich-text-renderer';
import { NavCard } from '@ldfeplatform/drx-component-library.ui.molecules.nav-card';
import type { FunctionComponent, Key } from 'react';
import { HeadingSubheadingComponent } from '../component';
import type { PchArticlePageWithContent } from '../contentful';

export type PchArticleTemplateProps = {
	content?: PchArticlePageWithContent;
	loading?: boolean;
};

export const ArticlePageTemplate: FunctionComponent<PchArticleTemplateProps> = ({ content, loading = false }) => {
	const theme = useTheme<Tokens>();

	if (loading) {
		return <></>;
	}

	if (content?.__typename !== 'PchArticleTemplate') {
		return <Notice text="Invalid content returned from CMS" />;
	}

	const { articleHeadingSubheading, paragraphsCollection, breadcrumbsCtAsCollection, featureCardsCollection } = content;

	const callToActionItems = breadcrumbsCtAsCollection?.items[0]?.callToActionListCollection?.items;

	const breadcrumbItems: BreadCrumbItem[] =
		callToActionItems?.map((cta) => ({
			identifier: cta?.label || '',
			linkUrl: cta?.linkUrl || '',
		})) || [];

	const headSpecs = {
		pt: { base: 0 },
		pb: { base: 4 },
	};
	const subHeadSpecs = {
		pb: { base: 0 },
	};

	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				{paragraphsCollection && paragraphsCollection?.items?.length > 0 ? (
					<Flex
						py={10}
						px={{
							base: 8,
							lg: 32,
							'2xl': 64,
						}}
					>
						{breadcrumbsCtAsCollection && <BreadcrumbNav content={breadcrumbItems} />}
					</Flex>
				) : null}

				{featureCardsCollection && featureCardsCollection?.items?.length > 0 ? (
					<Flex
						py={10}
						px={{
							base: 8,
							'2xl': 32,
						}}
					>
						{breadcrumbsCtAsCollection && <BreadcrumbNav content={breadcrumbItems} />}
					</Flex>
				) : null}

				{paragraphsCollection && paragraphsCollection?.items?.length > 0 ? (
					<Flex
						px={{
							base: 0,
							lg: 24,
							'2xl': 32,
						}}
						pb={{
							base: 16,
							'2xl': 20,
						}}
					>
						<HeadingSubheadingComponent
							heading={articleHeadingSubheading}
							headSpecs={headSpecs}
							subHeadSpecs={subHeadSpecs}
						/>
					</Flex>
				) : null}

				{featureCardsCollection && featureCardsCollection.items?.length > 0 ? (
					<>
						<Flex px={0} pb={16}>
							<HeadingSubheadingComponent
								heading={articleHeadingSubheading}
								headSpecs={headSpecs}
								subHeadSpecs={subHeadSpecs}
							/>
						</Flex>

						<Grid
							gap={{ base: 5, '2xl': 8 }}
							templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
							px={{ base: 8, '2xl': 32 }}
							pb={{ base: 20, '2xl': 24 }}
						>
							{featureCardsCollection.items.map((item) => {
								if (!item) {
									return <></>;
								}
								const navCardProps = getSDUINavCardProps(item);
								return (
									<GridItem key={`${item.heading}${item.body}`}>
										<NavCard {...navCardProps} hideCtaButton={true} />
									</GridItem>
								);
							})}
						</Grid>
					</>
				) : null}

				<Box
					px={{
						base: 8,
						lg: 32,
						'2xl': 64,
					}}
				>
					{paragraphsCollection?.items?.map((obj, index) => (
						<Box key={index}>
							{obj?.context?.json?.content?.map((contentObj: object, contentIndex: Key) => (
								<Box pb={4} key={contentIndex}>
									<RichTextRenderer content={contentObj} />
								</Box>
							))}
						</Box>
					))}
				</Box>

				<Spacer
					pb={{
						base: 16,
						'2xl': '11.5rem',
					}}
				/>
			</Container>
		</>
	);
};
