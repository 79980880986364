import { loginCustomAction, logoutCustomAction, signupCustomAction } from '@/entities/account';
import { createEvent, sample } from 'effector';
import { showToast } from '../toast';
import { closeModalCustomAction } from './closeModal';
import { giveConsentCustomAction } from './giveConsentCustomAction';
import { startAppointmentBookingCustomAction } from './startAppointmentBooking';

const deleteAccountCustomAction = createEvent<unknown>();
const actionMap = {
	'start-appointment-booking': startAppointmentBookingCustomAction,
	'give-consent': giveConsentCustomAction,
	'close-modal': closeModalCustomAction,
	'delete-account': deleteAccountCustomAction,
	login: loginCustomAction,
	logout: logoutCustomAction,
	signup: signupCustomAction,
};

export type CustomActionIdentifier = keyof typeof actionMap;

export type CustomActionsDispatcherParams = {
	identifier: string;
	payload: unknown;
};

export const dispatchCustomAction = createEvent<CustomActionsDispatcherParams>();

const validIdentifiers = Object.entries(actionMap);

validIdentifiers.forEach(([identifier, action]) => {
	sample({
		clock: dispatchCustomAction,
		filter: (action) => action.identifier === identifier,
		fn: (a) => a.payload,
		target: action,
	});
});

sample({
	clock: dispatchCustomAction,
	filter: (action) => !Object.keys(actionMap).includes(action.identifier),
	fn: (action) => ({
		message: `Unknown Custom Action: ${action.identifier}`,
		isSuccess: false,
	}),
	target: showToast,
});
