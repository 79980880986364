import { MidtierProfileBaseUrl } from '@/shared/config';
import Axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

export const axiosUser = Axios.create({ baseURL: MidtierProfileBaseUrl }); // use your own URL here or environment variable

// add a second `options` argument here if you want to pass extra options to each generated query
export const userClient = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	const source = Axios.CancelToken.source();
	const promise = axiosUser({
		...config,
		...options,
		cancelToken: source.token,
	});

	// @ts-expect-error promise.cancel is not spec
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};
