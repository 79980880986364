import { useSessionContext } from '@/shared/clientSession';
import { useEnvironment } from '@/shared/environment';
import {
	EventName as EventNameEnum,
	getDataLayerState,
	type Page,
	type User,
} from '@sdm/analytics-helper-library-pchweb';
import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { useProfileContext } from '../ProfileContext';
import { ANONYMOUS, BANNER_PCH, GUEST, LOGGED_IN, NOT_LOGGED_IN, REGISTERED, USER_STATE } from './snowplowConstants';
import type { EventName, EventParamsMap, SnowplowContextParams } from './snowplowTypes';
import { addClearEventParams, loadSnowplow, updateAnalyticsLayer } from './snowplowUtils';

export const SnowplowContext = createContext<SnowplowContextParams | null>(null);

export const SnowplowProvider = ({ children }: PropsWithChildren) => {
	const { accountStatus } = useProfileContext();
	const environment = useEnvironment();
	const { isSnowplowEnabled } = environment;

	const session = useSessionContext();
	const pcid = session.status === 'authenticated' ? session.pcid : null;

	const track = useCallback(
		<T extends EventName>(event: T, params: EventParamsMap[T]): Promise<void> | undefined => {
			if (isSnowplowEnabled && event !== USER_STATE) {
				return new Promise((resolve, reject) => {
					loadSnowplow()
						.then(() => resolve(updateAnalyticsLayer(event, addClearEventParams(event, params))))
						.catch((error) => reject(error));
				});
			}
		},
		[isSnowplowEnabled]
	);

	const pageView = useCallback(
		async (params: Page) => {
			/**
			 * Before any page view call, ensure user context setup is done
			 * if current analytics data layer does not have any user context OR its `accountStatus` does not match,
			 * then make a track call and update `user_state` for snowplow tracking
			 */

			const userData = getDataLayerState()?.user;
			const singleUser = Array.isArray(userData) ? userData[0] : userData;

			const user: User = {
				pcid_id: session.status === 'authenticated' && pcid ? pcid : ANONYMOUS,
				pco_wallet_id: null, // @Todo: check how to get wallet id
				login_status: session.status === 'authenticated' ? LOGGED_IN : NOT_LOGGED_IN,
				registration_status: accountStatus === 'fetched' ? REGISTERED : GUEST,
				accountStatus,
			};

			// Now, safely check accountStatus on a single User not on a potential User[]
			if (singleUser?.accountStatus && singleUser?.accountStatus !== accountStatus) {
				await track(USER_STATE, { user });
			} else {
				await track(EventNameEnum.PageView, {
					page: params,
					shopping_experience: { banner: BANNER_PCH },
					user,
				});
			}
		},
		[session.status, pcid, accountStatus, track]
	);
	// Memoize content. Only update if next and previous state differ
	const memoizedContext = useMemo(() => ({ pageView, track }), [pageView, track]);

	return <SnowplowContext.Provider value={memoizedContext}>{children}</SnowplowContext.Provider>;
};

export const useSnowplow = () => {
	const context = useContext(SnowplowContext);
	if (!context) {
		throw new Error(`useSnowplow must be used within <SnowplowContext.Provider>`);
	}
	return context;
};
