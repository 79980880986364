import type {
	CallToAction,
	CTAVariant,
	DamAsset as SDUIDamAsset,
} from '@ldfeplatform/drx-component-library.shared-types.types';
import type { BannerFieldsFragment, CallToActionFieldsFragment, DamAssetFieldsFragment, Maybe } from '../contentful';

export function convertDamAsset(asset?: Maybe<DamAssetFieldsFragment>): SDUIDamAsset {
	return {
		asset: { url: asset?.asset?.url || '' },
		altText: asset?.altText || '',
	};
}

export function parseVariant(variant?: string | null): CTAVariant {
	switch (variant) {
		case 'primary':
		case 'secondary':
		case 'tertiary':
			return variant;
		default:
			return 'tertiary';
	}
}

export function convertCTA(cta?: CallToActionFieldsFragment | null): CallToAction | undefined {
	if (!cta) {
		return undefined;
	}
	return {
		__typename: 'CallToAction',
		label: cta.label || '',
		linkUrl: cta.linkUrl || '',
		target: cta.target || '_self',
		variant: parseVariant(cta.variant),
		dataAnalytics: cta.dataAnalytics || '',
	};
}

export function convertCTAWithParameters(
	cta?: BannerFieldsFragment['alternateLink'],
	urlParameters?: string
): CallToAction | undefined {
	if (!cta) {
		return undefined;
	}
	const linkUrlWithPath = cta.linkUrl ? cta.linkUrl + urlParameters : cta.linkUrl || '';
	return {
		__typename: 'CallToAction',
		label: cta.label || '',
		linkUrl: linkUrlWithPath || '',
		target: cta.target || '_self',
		variant: parseVariant(cta.variant),
		dataAnalytics: cta.dataAnalytics || '',
	};
}
