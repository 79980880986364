import {
	CONTENTFUL_DELIVERY_API_ACCESS_TOKEN,
	CONTENTFUL_GRAPHQL_URL,
	CONTENTFUL_PREVIEW_API_ACCESS_TOKEN,
} from '@/shared/config';
import { browserLogger } from '@/shared/instrumentation/browserLogger';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, disableFragmentWarnings, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fetch } from 'cross-fetch';
import { Kind, OperationTypeNode, stripIgnoredCharacters, type ASTNode, type OperationDefinitionNode } from 'graphql';
import type { Logger } from 'pino';

disableFragmentWarnings();

const getQueryName = (node: ASTNode) => {
	switch (node.kind) {
		case Kind.DOCUMENT: {
			const op = node.definitions.find(
				(def): def is OperationDefinitionNode =>
					def.kind === Kind.OPERATION_DEFINITION && def.operation === OperationTypeNode.QUERY
			);
			return op?.name?.value;
		}
	}
	return undefined;
};

export function createHttpLink(logger: Logger, apq = false) {
	return new HttpLink({
		uri: CONTENTFUL_GRAPHQL_URL,
		print(node, originalPrint) {
			const query = originalPrint(node);
			// Stripping behaviour is there for non-persisted queries, as their hash gets computed on a non-stripped version
			const processedQuery = apq ? query : stripIgnoredCharacters(query);

			logger.info({ querySize: processedQuery.length, name: getQueryName(node), apq });

			return processedQuery;
		},
		fetch,
	});
}

function hasCode(e: unknown): e is { contentful: { code: string } } {
	return (
		e !== null &&
		typeof e === 'object' &&
		'contentful' in e &&
		typeof e.contentful === 'object' &&
		e.contentful !== null &&
		'code' in e.contentful
	);
}

export const createErrorLink = (logger: Logger) =>
	onError(({ graphQLErrors, networkError, operation }) => {
		if (graphQLErrors) {
			for (const err of graphQLErrors) {
				if (hasCode(err.extensions)) {
					if (err.extensions.contentful.code === 'UNRESOLVABLE_LINK') {
						continue;
					}
					logger.error({
						contentfulError: {
							message: err.message,
							code: err.extensions.contentful.code,
							operationName: operation.operationName,
							variables: operation.variables,
						},
					});
				} else {
					logger.error({ graphQLError: err });
				}
			}
			return;
		}
		if (networkError) {
			logger.error({ networkError });
		}
	});

export const createAuthMiddleware = () =>
	new ApolloLink((operation, forward) => {
		// add the authorization to the headers
		// conditionally change API key if content preview mode
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				Authorization: `Bearer ${
					operation.variables.preview ? CONTENTFUL_PREVIEW_API_ACCESS_TOKEN : CONTENTFUL_DELIVERY_API_ACCESS_TOKEN
				}`,
				'Content-Type': 'application/json',
			},
		}));

		return forward(operation);
	});

export const localeOverrideMiddleware = new ApolloLink((operation, forward) => {
	if (!operation.variables) {
		operation.variables = {
			locale: 'default',
		};
	}
	if (operation.variables.locale === 'default') {
		operation.variables.locale = 'en';
	}
	return forward(operation);
});

export const cache = new InMemoryCache({});

export const client = new ApolloClient({
	cache,
	link: from([
		createErrorLink(browserLogger),
		createAuthMiddleware(),
		localeOverrideMiddleware,
		createHttpLink(browserLogger),
	]),
});
